<template>
    <div>
		<el-row :gutter="20">
		
			<el-col :span="22">
				<div class="grid-content bg-purple-light">
					<div class="search">
						<el-form :inline="true"  ref="formInline" :model="formInline" class="demo-form-inline">
							<el-form-item label="推广员" prop="promoter">
								<el-input v-model="formInline.promoter" placeholder="推广员"></el-input>
							</el-form-item>

							<el-form-item label="审核" prop="status">
								<el-select v-model="formInline.status" placeholder="审核">
									<el-option label="待审核" value="2"></el-option>
									<el-option label="审核成功" value="3"></el-option>
									<el-option label="已驳回" value="4"></el-option>
									<el-option label="申请中" value="6"></el-option>
									<el-option label="提现失败" value="7"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="getList()">查询</el-button>
								<el-button @click="reset('formInline')">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-table ref="listTable" v-loading="loading" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" :data="data" lazy style="width: 100%;"  border="" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column align="center" prop="id" label="编号" sortable width="200"></el-table-column>
			<el-table-column align="center" prop="name" label="推广员" >
				<template slot-scope="scope" >
					{{scope.row.promoter.name}}
                </template>
			</el-table-column>
			<el-table-column align="center" label="类型" width="120">
				<template slot-scope="scope" >
					<el-tag v-if="scope.row.status==2" type="info">待审核</el-tag>
					<el-tag v-else-if="scope.row.status==3" type="success">审核成功</el-tag>
					<el-tag v-else-if="scope.row.status==4" type="danger">已驳回</el-tag>
					<el-tag v-else-if="scope.row.status==6" type="danger">申请中</el-tag>
					<el-tag v-else-if="scope.row.status==7" type="danger">提现失败</el-tag>
					<TextOverflow v-if="scope.row.status==7">{{scope.row.reason}}</TextOverflow>
				</template>
			</el-table-column>
            
			<el-table-column align="center" prop="money" label="提现金额"></el-table-column>
			<el-table-column align="center" prop="created_at" label="创建时间"></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" width="200">
				<template slot-scope="scope"  >
					<template v-if="scope.row.status==2||scope.row.status==7">
						<el-button  @click="withdrawal(scope.row.id,3)" type="text" size="small">通过</el-button>
						<el-button  @click="withdrawal(scope.row.id,4)" type="text" size="small" style="color: #F56C6C;">驳回</el-button>

					</template>
					<!-- <template v-else-if="scope.row.status==2||scope.row.status==7">
					</template> -->
					
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @prev-click="prev" @next-click="next" @current-change="CurrentChange" layout="total,prev, pager, next" :page-size="pageSize" :total="total"></el-pagination>
	</div>
</template>
<script>
	import {request} from "@/library/request.js";
	import TextOverflow from "components/common/TextOverflow.vue"
	export default {
		components:{
			TextOverflow
		},
		data(){
			return{
				data:[],
				total:0,
				pageSize:15,
				currentPage:0,
                ModalTitle:"",
				loading: false,
				formInline:{
					promoter:"",
					status:""
				},
			}
		},
        methods: {
            getList(){
				this.loading = true
				this.$Loading.start();
				var search = {
					limit:this.pageSize,
					page:this.currentPage,
					promoter:this.formInline.promoter,
					status:this.formInline.status,
				}
				return request({
					url:'finance/withdrawal/list',
					method:"get",
					params:search
				}).then(response  => {
					
					this.$Loading.finish();
					this.loading = false
					this.data = response.data.data
					this.total = response.data.total
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			prev(value){
				this.currentPage = value
				this.getList()
			},
			next(value){
				this.currentPage = value
				this.getList()
			},
			CurrentChange(value){
				this.currentPage = value
				this.getList()
			},
			reset(formName){
				this.$refs[formName].resetFields();
				this.getList()
			},
			withdrawal(id,status){
				this.loading = true
				return request({
					url:'finance/withdrawal/status/'+id,
					method:"post",
					params:{status:status}
				}).then(response  => {
					this.loading = false
					if(response.code==200){
						this.$message.success(response.msg)
					}else{
						if(response.msg){
							this.$message.error(response.msg)
						}else{
							this.$message.error("提现失败")
						}
					}
					this.getList();
				}).then(err => {
					if(err){
						this.loading = false
						this.$message.error("系统异常，请稍后重试")
					}
				})
			},
        },
        mounted() {

            this.getList()
        }
    }
</script>
<style source>
	@import url('/css/app.css');

</style>